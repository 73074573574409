import React from 'react'
import Link from 'next/link'

import Layout from 'components/layout'

const _404 = () => (
  <Layout>
    <div className="flex flex-col items-center justify-center ">
      <div className="mb-20 max-w-screen-sm w-full">
        <h1 className="mb-8">エラー</h1>
        <p>ページが見つかりません。</p>
        <p className="mb-4">404 Not Found</p>

        <Link href="/" className="text-blue-500 opacity-100 underline">
          トップページへ
        </Link>
      </div>
    </div>
  </Layout>
)

export default _404
